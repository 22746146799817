import '@glass/shared/helpers/Sentry';
import App from '@glass/shared/components/App/App';
import withTheme from '@glass/shared/components/App/withTheme';
import compose from '@glass/shared/helpers/compose';
import withCookies from '@glass/shared/components/App/withCookies';
import withApollo from '@glass/shared/components/App/withApollo';
import withRedux from '@glass/shared/components/App/withRedux';
import baseJss from '@glass/shared/components/App/baseJss';
import { withStyles } from '@material-ui/styles';
import theme from '@glass/rock/modules/theme';
import 'pure-react-carousel/dist/react-carousel.es.css';
import subscribe from '@glass/shared/modules/store/subscribe';
import initUserConfig from '@glass/shared/modules/userConfig/initUserConfig';

export default compose(
  withCookies({ ssr: true }),
  withApollo({ ssr: true }),
  withRedux({ ssr: true, subscribe, onCreateStore: initUserConfig }), // must follow apollo and cookies
  withTheme(theme),
  withStyles(baseJss),
)(App);

// export default App;
